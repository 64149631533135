/* 
BEGIN REMOVE MARGIN/PADDINGS
Since we don't use deep nesting, we don't need the margins and paddings
*/
.query-builder {
  margin: 0;
}

.query-builder > .group-container {
  padding-right: 0;
}

.query-builder .group {
  background: rgba(200, 200, 200, 0.5);
  border: 1px solid transparent;
}

.query-builder .group-or-rule {
  background: rgba(200, 200, 200, 0.2); 
}
/* 
END REMOVE MARGIN/PADDINGS  
*/

/* a bit more space for widget separators */
.query-builder .widget--sep {
  padding: 6px;
}

/* 
BEGIN HIDE OUTER GROUP
we hide the outer group and have all inner groups in an OR conjunction
no need to do anything furthoer down as we restict the level of nesting per maxNesting in config settings
*/
.query-builder > .group-container:first-child > .group > .group--header > .group--conjunctions {
  display: none;
}

.query-builder > .group-container:first-child > .group > .group--header::before {
  left: 0;
  border: none;
}

.query-builder > .group-container:first-child > .group > .group--children {
  padding-left: 0;
}
/* 
END HIDE OUTER GROUP
*/

/* make all buttons align right */
.query-builder .group .group-or-rule-container {
  padding-right: 0;
}

/* we don't want "add rule" on the outer level */
.query-builder .group .group--actions button:first-child {
  display: none;
}

/* but we want "add rule" on the children level */
.query-builder .group--children .group--actions button:first-child {
  display: flex;
}
